/*
 * WARNING: This file is owned by ws-scripts and can not be edited!
 *
 * NOTE: The eslint-disable-lines are needed because this file won't lint
 * when it's in the ws-scripts repo because those files only exist in the
 * widget. It's safe because the entire application won't work if those
 * weren't really resolved. It would be really apparent quickly.
 */

import {
	createStore as reduxCreateStore,
	combineReducers,
	applyMiddleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers'; // eslint-disable-line import/no-unresolved
import requestDataReducer from './reducers/request-data';
import prefsReducer from './reducers/prefs';
import labelsReducer from './reducers/labels';
import sitemapReducer from './reducers/sitemap';
import flagsReducer from './reducers/flags';
import customMiddleware from '../middleware'; // eslint-disable-line import/no-unresolved

const providedReducers = {
	requestData: requestDataReducer,
	prefs: prefsReducer,
	labels: labelsReducer,
	sitemap: sitemapReducer,
	flags: flagsReducer
};

const reducers = combineReducers({
	...rootReducer,
	...providedReducers
});

const middleware = [...customMiddleware, thunkMiddleware];

const locationContains = search => {
	return window.location.toString().indexOf(search) !== -1;
};

const isBrowser = typeof window !== 'undefined';
const showReduxLoggerFromUrlParam =
	isBrowser &&
	(locationContains('_mode=debug') ||
		locationContains('wsScriptsUseReduxLogger=true'));

if (
	isBrowser &&
	(process.env.NODE_ENV === 'development' || showReduxLoggerFromUrlParam)
) {
	// Note: This must the be last middleware in the chain so that it logs actions
	// instead of thunks or Promises.

	// Use an actionTransformer to get the widget name into the output since
	// we have multiple stores running on one page. It may seem like using
	// titleFormatter makes more sense for this purpose, but doing so loses the
	// readability improvement coloring added in 2.10.0 of redux-logger. As of
	// 3.0.6, there is no way to get colors without using the default title formatter.
	// With the below action transformer and default title formatter, we get:
	// action ws-example - MIDDLEWARE_TEST @ 17:47:41.612 (in 0.30 ms)
	const actionTransformer = action => ({
		...action,
		type: `ws-ld-tracker - ${action.type}`
	});

	const { createLogger } = require('redux-logger');
	const logger = createLogger({ actionTransformer, duration: true });
	middleware.push(logger);
}

const enhancer = applyMiddleware(...middleware);

export function createStore(initialState) {
	const store = reduxCreateStore(reducers, initialState, enhancer);

	if (module.hot) {
		module.hot.accept('../reducers', () => {
			const defaultReducers = require('../reducers').default; // eslint-disable-line import/no-unresolved
			store.replaceReducer(
				combineReducers({
					...defaultReducers,
					...providedReducers
				})
			);
		});
	}

	return store;
}
