import { SET_LAUNCHDARKLY_FLAGS } from '../actions/types';

export default (state = {}, { type, payload, error }) => {
	if (!error && payload !== undefined) {
		switch (type) {
			case SET_LAUNCHDARKLY_FLAGS:
				return payload;

			default:
				return state;
		}
	}

	return state;
};
